<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
  
    <POrderGrid  
      ref="ordergrid"
      transactionType="TO,HE"
      :locationIdFrom="location.id"
      :cols="cols" 
      :filters="filters"
      :orderBy="orderBy"
      :expandAll="expandAll"      
      @click="onClick"
    ></POrderGrid>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <portal to="actions">
      <div class="ml-4 space-x-4 flex items-center">
        
        <PToggle 
          v-model="expandAll" 
          :label="$tk('Common.General.Detailed')" 
          :stacked="true" 
        />

        <PButton           
          v-hint="{ show: hintsVisible, text: 'N' }"          
          v-shortkey.push="['alt', 'n']"          
          @shortkey.native="onCreateShow"
          @click="onCreateShow"
          color="primary"
          :disabled="customer.statusId === 2 || location.statusId === 2"          
        >
          <span v-html="(location.isPooling || location.isCommission)
          ? $tk('Dispatch.NewPickup')
          : $tk('Dispatch.NewSale')
          "></span>
        </PButton>
      </div>
  </portal>

    <OrderDialog 
      v-if="orderDialogVisible" 
      transactionType="HE"
      :orderId="orderId"
      @close="onClose" 
    />

    <EditDialog 
      v-if="editDialogVisible" 
      transactionType="HE"
      :orderId="orderId"
      @close="onCloseEditDialog" 
      view="dispatch"
    />

  </div>
</template>

<script>

import { mapGetters } from "vuex"
import { format, add, sub } from "date-fns"
import OrderDialog from "@/areas/orders/dialogs/OrderDialog.vue"
import EditDialog from "@/areas/reception/dialogs/EditDialog.vue"
import { getConfig } from "@/config"

const config = getConfig();

export default {
  
  components: {
    OrderDialog,
    EditDialog
  },

  props: {
    id: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      cols: 
      
      config.theme === "swa" ?
      [        
        { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, xalign: "center", classes: "font-medium" },
        { text: this.$tk("Common.Order.PickupDate"), value: "dateSend", sortable: true, component: "p-display-date", width: 110, align: "center" },
        { 
          nested: [            
            { text: this.$tk("Common.Order.RTI"), component: "p-display-product", componentConfig: { showTreatment: true }, aggregate: "p-display-product-images", align: "left", width: 220 },
            { text: this.$tk("Common.Order.Quantity"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" }, 
          ] 
        },
        { text: this.$tk("Common.Order.Freight"), value: "feeTransport", sortable: true, component: "p-display-price", align: "right", width: 80 },
        { text: this.$tk("Common.Order.Currency"), value: "currencyIdFrom", sortable: true, component: "p-display-text", width: 90, align: "center" },
        { text: this.$tk("Common.Order.TempAddress"), value: "locationNameFrom", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.OrderedBy"), value: "createdBy", sortable: true, component: "p-display-user" },
        { text: this.$tk("Common.Order.Cartman"), value: "cartmanName", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.TransportRef"), value: "shipmentRef", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.CarNo"), value: "shipmentCarNumber", sortable: true, component: "p-display-text", align: "center" },
        { text: this.$tk("Common.Order.MyReference"), value: "sendersRef", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.CarNo2"), value: "shipmentCarNumber2", component: "p-display-text" },
        { text: this.$tk("Common.Order.Comments"), value: "commentCount", component: "p-display-comments", width: 50, align: "center" },        
        { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
      ]
      
      :

      [        
        { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, xalign: "center", classes: "font-medium" },
        { text: this.$tk("Common.Order.PickupDate"), value: "dateSend", sortable: true, component: "p-display-date", width: 110, align: "center" },
        { 
          nested: [            
            { text: this.$tk("Common.Order.RTI"), component: "p-display-product", componentConfig: { showTreatment: true }, aggregate: "p-display-product-images", align: "left", width: 220 },
            { text: this.$tk("Common.Order.Quantity"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" }, 
            { text: this.$tk("Common.Order.Amount"), value: "feePrice", component: "p-display-price", width: 80, align: "right" },
          ] 
        },
        { text: this.$tk("Common.Order.Freight"), value: "feeTransport", sortable: true, component: "p-display-price", align: "right", width: 80 },
        { text: this.$tk("Common.Order.Total"), value: "amountFrom", sortable: true, component: "p-display-price", width: 110, align: "right" },
        { text: this.$tk("Common.Order.Currency"), value: "currencyIdFrom", sortable: true, component: "p-display-text", width: 90, align: "center" },
        { text: this.$tk("Common.Order.TempAddress"), value: "locationNameFrom", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.OrderedBy"), value: "createdBy", sortable: true, component: "p-display-user" },
        { text: this.$tk("Common.Order.Transporter"), value: "transporterName", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.TransportRef"), value: "shipmentRef", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.CarNo"), value: "shipmentCarNumber", sortable: true, component: "p-display-text", align: "center" },
        { text: this.$tk("Common.Order.MyReference"), value: "sendersRef", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.PickupTo"), sortable: true, component: "p-display-counterpart" },
        { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
      ]
      
      ,      
      orderId: null,      
      expandAll: true,
      filters: {
        term:                { value: "" },
        sendLower:           { value: format(sub(new Date(), { days: 21 }), "yyyy-MM-dd") },
        sendUpper:           { value: format(add(new Date(), { months: 1 }), "yyyy-MM-dd") },
        statuses: [
          { label: this.$tk("Common.OrderStatus.Open"), ids: [1, 2, 3, 10], value: true },
          { label: this.$tk("Common.OrderStatus.Sent"), ids: [4, 12], value: true },
          { id: 6, value: false },
          { id: 9, value: true, visible: false },
          { id: 5, value: false },
        ]        
      },
      orderBy: {
        field: "id",
        descending: false
      }, 
      orderDialogVisible: false,
      editDialogVisible: false
    }
  },  
  
  computed: {
    ...mapGetters([
      "customer",
      "location",
      "hintsVisible"
    ]),
  },

  methods: {

    onCreateShow () {
      this.orderDialogVisible = true
    },

    onClick ({ order }) {
      
      if (order.interpoolingCompany) return
      
      this.orderId = order.id
      
      if (order.transactionStatus === "Web" ||
          order.transactionStatus === "Ordered") {
        this.orderDialogVisible = true
      } else {
        this.editDialogVisible = true
      }
    },
    onClose () {      
      this.orderId = null
      this.orderDialogVisible = false
      this.$refs.ordergrid.getOrders()
    },
    onCloseEditDialog () {
      this.orderId = null
      this.editDialogVisible = false
      this.$refs.ordergrid.getOrders()
    }   
  },

  created () {
    if (this.id) {
      this.orderId = this.id
      this.editDialogVisible = true
    }
  }
}
</script>
